<template>
  <div class="mx-1">
    <div class="mb-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        :to="{ name: 'video-add'}"
      >
        Add Video
      </b-button>

    </div>
    <b-card-group deck>
      <b-card
        v-for="video in videos"
        :key="video.id"
        no-body
      >
        <div class="item-img text-center">
          <b-link :to="{ name: 'video-details', params: { slug: video.slug } }">
            <b-img
              :alt="`${video.name}-${video.id}`"
              fluid
              class="card-img-top"
              :src="video.image"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'video-details', params: { slug: video.slug } }"
            >
              {{ video.name }}
            </b-link>
          </h6>
          <b-card-text class="item-description">
            {{ video.time }}
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-card-group>
    <b-pagination
      v-if="items_total > items_per_page"
      v-model="page"
      pills
      :total-rows="items_total"
      :per-page="items_per_page"
      @input="loadData"
    />
  </div>

</template>

<script>
import {
  BCard, BCardBody, BImg, BCardText, BLink, BCardGroup, BPagination, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BCardBody, BImg, BCardText, BLink, BCardGroup, BPagination, BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      videos: [],
      page: 1,
      items_total: 1,
      items_per_page: 1,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.$http.get(`/videos?page=${this.page}`).then(res => {
        this.videos = res.data.data.videos
        this.items_total = res.data.data.meta.total
        this.items_per_page = res.data.data.meta.per_page
      })
    },
  },

}
</script>
